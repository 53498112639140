import React, { useContext } from "react"
import tw from "twin.macro"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import BlockContent from "../../atoms/BlockContent"
import { InfoSeoSectionProps } from "./infoSeoSection.d"

const InfoSeoSection: React.FC<InfoSeoSectionProps> = ({
  infoSeoHeading,
  infoSeoDescription,
}) => {
  const [state, dispatch] = useContext(DisclaimersContext)

  return (
    <article
      css={[
        tw`relative px-12 max-w-7xl text-center mx-auto py-10 my-10`,
        tw`md:(items-center px-12 h-auto mb-20)`,
      ]}
    >
      {infoSeoHeading && (
        <h2
          css={[
            tw`text-2xl tracking-widest font-book mb-8 text-red-300 block`,
            tw`md:(text-5xl)`,
          ]}
        >
          {parseDisclaimerBlocks(infoSeoHeading, selection =>
            dispatch(toggleDisclaimersModal(selection))
          )}
        </h2>
      )}
      {infoSeoDescription && <BlockContent data={infoSeoDescription} />}
    </article>
  )
}

export default InfoSeoSection
