import React from "react"
import tw from "twin.macro"
import { ToyotacareHeaderProps } from "./Toyotacare.d"
import { Image } from "../../atoms/Image"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const ToyotacareHeader: React.FC<ToyotacareHeaderProps> = ({
  headerBackground,
  headerLogo,
  headingText,
  subHeadingText,
  availableServicesHeading,
  planDetails,
  availableServices,
}) => {
  return (
    <section
      aria-label="ToyotaCare Hero Section"
      css={[tw`relative flex `, tw`md:(items-center)`]}
    >
      {headerBackground && (
        <Image
          imageData={headerBackground.image}
          objectFit="cover"
          css={[tw`relative w-full bg-contain top-0`, "z-index: 0;"]}
        />
      )}

      {/* Hero Section */}
      {/* <section
        css={[
          tw`grid overflow-hidden h-auto z-10 w-full mx-auto px-8`,
          tw`md:(gap-24 grid-cols-2 grid-flow-row max-w-7xl px-4 grid-rows-1)`,
        ]}
      >

        <div
          css={[
            tw`col-span-2`,
            tw`md:(col-span-2 flex justify-center items-center)`,
          ]}
        >
          {headerLogo?.image && (
            <Image
              imageData={headerLogo.image}
              alt={headerLogo.alt}
              objectFit="contain"
              css={[
                tw`relative mt-52 block col-span-2`,
                tw`md:(block col-span-2 order-4 top-24 w-5/12)`,
              ]}
            />
          )}
        </div>
        <div css={[tw`col-span-2 text-white`, tw`md:(col-span-1)`]}>
          <h1
            css={[
              tw`text-3xl tracking-widest w-64 py-4 font-light`,
              tw`md:(text-4xl tracking-widest font-light leading-normal w-3/4 pb-8)`,
            ]}
          >
            {headingText}
          </h1>
          <p>{subHeadingText}</p>
        </div>

 <div
          css={[tw`text-white col-span-2 pt-4`, tw`md:(col-span-1 order-1)`]}
        >
          <h3 css={[tw`tracking-widest text-xl font-light pb-4`]}>
            {availableServicesHeading}
          </h3>
          <ul
            css={[
              tw`ml-5 mb-8`,
              tw`md:(grid overflow-visible grid-cols-2 grid-rows-1 gap-2 grid-flow-row w-auto h-auto mb-16)`,
            ]}
          >
            {availableServices.map((service: any) => (
              <li css={[tw`list-disc list-outside text-xl font-semibold mr-6`]}>
                {service}
              </li>
            ))}
          </ul>
           <div css={[tw`my-6`]}>
            <Button primary>Dealers</Button>
            <Link
              to={`https://cdn.sanity.io/${planDetails.brochure.asset.path}`}
              css={[tw`ml-4`]}
              target={`_blank`}
            >
              Brochure
            </Link>
          </div>
        </div>
      </section>*/}
    </section>
  )
}

export default ToyotacareHeader
