import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { ToyotacareDetailsProps, ToyotacareService } from "./Toyotacare.d"
import { Image } from "../../atoms/Image"
import Icon from "../../atoms/Icon"
import { LanguageContext } from "../../../contexts/Language"
import { ButtonA } from "../../atoms/Button"
import { parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const ToyotacareDetails: React.FC<ToyotacareDetailsProps> = ({
  planDetails,
}) => {
  const [selectedService, setSelectedService] = useState<ToyotacareService>()
  const { _ } = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)
  const { trackTealEvent } = useTealiumEvent()

  useEffect(() => {
    setSelectedService(planDetails.services[0])
  }, [])

  return (
    <section
      aria-label="ToyotaCare Details Section"
      css={[tw`bg-gradient-to-t from-gray-100 via-gray-100 to-gray-50`]}
    >
      <div
        css={[
          tw`grid grid-cols-2 grid-rows-1 p-4 grid-flow-row h-auto z-10 max-w-7xl w-full mx-auto px-4`,
          tw`md:(gap-28)`,
        ]}
      >
        <div css={[tw`col-span-2`, tw`md:(mt-32 col-span-1)`]}>
          <h4
            css={[
              tw`text-2xl mt-6 tracking-widest font-light leading-normal pb-8`,
            ]}
          >
            {_("What’s included in the Maintenance Plan?")}
          </h4>
          <h1 css={[tw`text-xl leading-normal font-semibold`]}>
            {parseDisclaimerBlocks(planDetails.description, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </h1>
          {planDetails.brochure && (
            <ButtonA
              primary
              href={`https://cdn.sanity.io/${planDetails.brochure.asset?.path}`}
              target={`_blank`}
              alt="Download Brochure"
              css={[tw`mt-8`]}
              onClick={() => {
                return trackTealEvent({
                  // url: `https://cdn.sanity.io/${planDetails.brochure.asset?.path}`
                  tealium_event: "download_brochure",
                })
              }}
            >
              {_("Download Brochure")}
            </ButtonA>
          )}
        </div>
        <div
          css={[
            tw`col-start-1 row-start-1 col-span-2 flex justify-center items-center`,
            tw`md:(mt-32 col-span-1 col-start-2)`,
          ]}
        >
          {planDetails.logo && (
            <Image imageData={planDetails.logo} css={[tw`w-3/4 mt-14`]} />
          )}
        </div>

        <div css={[tw`col-span-2 px-0 w-full mt-6`, tw`md:(mx-auto px-4)`]}>
          <div css={[tw`overflow-auto overflow-x-scroll w-full`]}>
            <div css={[tw`flex flex-grow justify-between`]}>
              {planDetails.services.map((service: any) => {
                const TabIcon = Icon[service?.icon]
                return (
                  <button
                    css={[
                      tw`text-xs font-semibold uppercase px-8  py-4 text-center z-10 w-[142px]`,
                      tw`md:(text-base w-auto)`,
                      selectedService &&
                        selectedService.name === service?.name &&
                        tw`flex-none rounded-t-xl px-4 bg-gray-50 shadow-4`,
                      tw`md:(p-0)`,
                      tw`md:(rounded-t-xl text-center py-6 px-8)`,
                    ]}
                    onClick={() => setSelectedService(service)}
                  >
                    {TabIcon && (
                      <TabIcon
                        color="red-400"
                        css={[tw`max-h-10 mx-auto pb-2`]}
                      />
                    )}
                    {service?.title}
                  </button>
                )
              })}
            </div>
          </div>

          {selectedService && (
            <>
              <div
                css={[
                  tw`relative z-20 grid grid-cols-2 grid-rows-1 grid-flow-row w-auto h-auto p-6 shadow-4 mb-20 rounded-xl -mt-2 bg-white`,
                  tw`md:(-top-2 gap-24 p-14 shadow-5)`,
                ]}
              >
                <div
                  css={[
                    tw`text-xl leading-normal col-span-2`,
                    tw`md:(col-span-1)`,
                  ]}
                >
                  <h3 css={[tw`font-semibold pb-2`]}>{selectedService.name}</h3>

                  {parseDisclaimerBlocks(
                    selectedService.description,
                    selection => dispatch(toggleDisclaimersModal(selection))
                  )}
                </div>

                <div css={[tw`p-4 text-center items-center`]}>
                  {selectedService?.serviceImage?.image.asset
                    .gatsbyImageData && (
                    <Image
                      imageData={selectedService?.serviceImage?.image}
                      css={[tw``]}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default ToyotacareDetails
