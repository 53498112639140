import { graphql, PageProps } from "gatsby"
import React, { useContext, useEffect, useRef } from "react"
import { Layout } from "../components/atoms/Layout"
import ToyotacareDetails from "../components/organisms/Toyotacare/Details"
import ToyotacareHeader from "../components/organisms/Toyotacare/Header"
import RoadsideAssistanceDetails from "../components/organisms/Toyotacare/RoadsideAssistanceDetails"
import VideoSection from "../components/molecules/VideoSection/Video"
import {
  Brochure,
  ImageBlocks,
  ToyotaCare,
} from "../components/organisms/Series"
import { SEO } from "../components/atoms/SEO"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import useDealers from "../hooks/useDealers"
import useTealiumNoZip from "../hooks/Tealium/useTealiumNoZip"
import { LocationContext } from "../contexts/Location"
import tw from "twin.macro"
import { ButtonLink } from "../components/atoms/Button"
import { CTA } from "../global"
import { getCTALinkValue } from "../helpers"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import CtaBox from "../components/molecules/Heros/CtaBox"
import { RetentionSalesEventHero } from "../components/molecules/RetentionSalesEventHero"
import { Find } from "../components/molecules/Find"
import PartAndServiceHero from "../components/organisms/PartAndServiceHero/PartAndServiceHero"
import { InfoSeoSection } from "../components/molecules/InfoSeoSection"
import TabbedImageModule from "../components/molecules/TabbedImageModule/TabbedImageModule"

const ToyotaCarePage: React.FC<PageProps> = ({ data, location }: any) => {
  const { page } = data

  // Tealium
  const { handleTealView } = useTealiumView()
  const { getDealerInfo } = useDealers()
  const pageload = useRef(true)
  const [isOutOfArea] = useTealiumNoZip()
  const [{ dealers }] = useContext(LocationContext)

  useEffect(() => {
    const dealerInfo = getDealerInfo()
    /* Fire only on inital page load, and not every time dealers is updated */
    if (isOutOfArea && pageload.current) {
      pageload.current = false
      handleTealView({
        page_type: "parts service",
        tealium_event: "parts_service_toyota_care",
        page_name: "toyota care",
        search_results: 0,
        dealer_code: "NOT SET",
        dealer_name: "NOT SET",
      })
    }

    if (pageload.current && dealers) {
      pageload.current = false
      handleTealView({
        page_type: "parts service",
        tealium_event: "parts_service_toyota_care",
        page_name: "toyota care",
        search_results: dealers?.length,
        dealer_code: dealerInfo.dealerCodes,
        dealer_name: dealerInfo.dealerNames,
      })
    }
  }, [dealers, isOutOfArea])

  const ctaBoxData = page?.content?.find(
    (section: any) => section?._type === "callToActionBox"
  )

  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      {ctaBoxData && <CtaBox {...ctaBoxData} />}
      <SanitySections sections={page?.content} title={page.title} />
    </Layout>
  )
}

const SanitySections: React.FC<{ sections: any; title: string }> = ({
  sections,
  title,
}): JSX.Element => {
  const retentionSalesEventData = sections?.find(
    (section: any) => section._type === "salesEventHeroPageBuilder"
  )?.salesEvent
  const retentionSalesEventHeroData = retentionSalesEventData?.salesEventActive
    ? retentionSalesEventData?.hero
    : null
  return (
    <>
      {sections?.map((section: any) => {
        switch (section?._type) {
          case "toyotaCareHero":
            return retentionSalesEventHeroData ? (
              <RetentionSalesEventHero {...retentionSalesEventHeroData} />
            ) : (
              <ToyotacareHeader {...section} />
            )
          case "partsAndServiceHero":
            if (retentionSalesEventHeroData) {
              return (
                <RetentionSalesEventHero {...retentionSalesEventHeroData} />
              )
            }
            return <PartAndServiceHero {...section} />
          case "toyotaCarePlanDetails":
            return <ToyotacareDetails {...section} />
          case "toyotaCareRoadsideDetails":
            return <RoadsideAssistanceDetails {...section} />
          case "videoSection":
            return <VideoSection {...section} />
          case "tabbedImageModule":
            return <TabbedImageModule {...section} />
          case "dealersNearYouSection":
            return (
              <DealersNearYou
                type="default"
                {...section}
                ctaAnalyticsId="schedule service"
              />
            )
          case "imageBlocksWithDescriptionSection":
            return <ImageBlocksWithDescription section={section} />
          case "salesEventImageWithTextPageBuilder":
            if (!section?.salesEvent?.salesEventActive) return null
            return (
              <ToyotaCare
                {...section?.salesEvent?.imageWithText}
                imagePosition={section.imagePosition}
              />
            )
          case "imageBlocks":
            return (
              <ImageBlocks
                imageBlocks={section?.imageBlocks}
                _key={"Image Blocks"}
                sectionSlug={{
                  current: "ImageBlocks",
                }}
              />
            )
          case "infoSeoSection":
            return <InfoSeoSection {...section} />
          case "brochure":
            return <Brochure {...section} />
        }
      })}
    </>
  )
}

export const query = graphql`
  query ToyotacarePageQuery($id: String) {
    page: sanityPageToyotacare(id: { eq: $id }) {
      id
      language
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      content {
        ...DealersNearYouData
        ...CallToActionBoxData
        ...ImageBlocksWithDescriptionData
        ...RetentionSalesEventImageWithTextData
        ...RetentionSalesEventHeroData
        ...ToyotaCareHero
        ...ToyotaCarePlanDetails
        ...VideoSectionData
        ...TabbedImageModuleData
        ...ToyotaCareRoadsideDetails
        ...FaqSectionData
        ...ImageBlocksSection
        ...PartsAndServiceHeroData
        ...InfoSeoSectionData
        ...BrochureData
      }
      disclaimers {
        code
        disclaimer
      }
    }
  }
`

export default ToyotaCarePage
